<!-- 学习 -->
<template>
  <div class="" no-scroll>
    <!-- 学习进度 -->
    <MeStudy :student_user_id="student_user_id"></MeStudy>
    <!-- 我的课程全部部分 -->
    <div class="bottom" v-if="!this.student_user_id && userInfo.type==2">
      <el-tabs
        v-model="tabActiveName"
        type="border-card"
        stretch
        @tab-click="$handleRoute(tabActiveName)"
      >
        <el-tab-pane
          :label="tab.name"
          :name="tab.value"
          v-for="(tab, index) in curTabList"
          :key="index"
        >
          <span slot="label">
            {{ tab.name }}
            <el-badge
              v-if="tab.name == '我的消息'"
              :value="infoData.msg_notread"
              class="item"
              :hidden="!infoData.msg_notread"
            ></el-badge>
          </span>
        </el-tab-pane>
      </el-tabs>
      <div style="padding: 20px">
        <router-view
          v-bind="{
            pageSize,
            page,
            total,
            pageStyle: { textAlign: 'center', padding: '20px 0' },
          }"
        ></router-view>
      </div>
    </div>
    <!-- 学生 -->
    <div class="bottom" v-if="!this.student_user_id && userInfo.type==1">
      <el-tabs
        v-model="tabActiveName"
        type="border-card"
        stretch
        @tab-click="$handleRoute(tabActiveName)"
      >
        <el-tab-pane
          :label="tab.name"
          :name="tab.value"
          v-for="(tab, index) in curTabList"
          :key="index"
        >
          <span slot="label">
            {{ tab.name }}
            <el-badge
              v-if="tab.name == '我的消息'"
              :value="infoData.msg_notread"
              class="item"
              :hidden="!infoData.msg_notread"
            ></el-badge>
          </span>
        </el-tab-pane>
      </el-tabs>
      <div style="padding: 20px">
        <router-view
          v-bind="{
            pageSize,
            page,
            total,
            pageStyle: { textAlign: 'center', padding: '20px 0' },
          }"
        ></router-view>
      </div>
    </div>
    <div class="bottom" v-if="this.student_user_id && this.student_user_id !== this.userInfo.id">
      <el-tabs
        v-model="tabActiveName"
        type="border-card"
        stretch
        @tab-click="$handleRoute(tabActiveName)"
      >
        <el-tab-pane
          :label="tab.name"
          :name="tab.value"
          v-for="(tab, index) in curTabList"
          :key="index"
        >
          <span slot="label">
            {{ tab.name }}
            <el-badge
              v-if="tab.name == '我的消息'"
              :value="infoData.msg_notread"
              class="item"
              :hidden="!infoData.msg_notread"
            ></el-badge>
          </span>
        </el-tab-pane>
      </el-tabs>
      <div style="padding: 20px">
        <router-view
          v-bind="{
            pageSize,
            page,
            total,
            pageStyle: { textAlign: 'center', padding: '20px 0' },
          }"
        ></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { userInfo2 } from "@/api/me.js";

// 学习进度
import MeStudy from "./components/MeStudy";
import { mapState } from "vuex";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { MeStudy },
  props: {
    student_user_id: {
      default: () => 0,
    },
  },
  data() {
    return {
      loading: false,
      taList: [
        // {name:'我的课程' , value:'meStudyCourse' },
        // {name:'我的题单' , value:'meStudyQuestList' },
        // {name:'我的题目' , value:'meStudyQuestion'},
        // {name:'我的比赛&考试&作业' , value:'meStudyQuestother'},
        // {name:'我的团队' , value:'meStudyTeam'},

        { name: "我的账单", value: "meBill" },
        { name: "我的消息", value: "meMessage" },
        { name: "我的论坛", value: "meStudyForum" },
        { name: "我的收藏", value: "meStudyCollet" },
        { name: "我的订单", value: "meStudyOrder" },
      ],
      // 学生页面
      taList3: [
        // { name: "我的账单", value: "meBill" },
        { name: "我的消息", value: "meMessage" },
        { name: "我的论坛", value: "meStudyForum" },
        { name: "我的收藏", value: "meStudyCollet" },
        { name: "我的订单", value: "meStudyOrder" },
      ],

      taList2: [
        {name:'我的课程' , value:'meStudyCourse' },
        {name:'我的题单' , value:'meStudyQuestList' },
        {name:'我的题目' , value:'meStudyQuestion'},
        {name:'我的比赛&考试&作业' , value:'meStudyQuestother'},
        // {name:'我的团队' , value:'meStudyTeam'},

        // { name: "我的账单", value: "meBill" },
        // { name: "我的消息", value: "meMessage" },
        // { name: "我的论坛", value: "meStudyForum" },
        // { name: "我的收藏", value: "meStudyCollet" },
        // { name: "我的订单", value: "meStudyOrder" },
      ],
      tabActiveName: "",
      curTabList: [],
      curTabList2: [],
      page: 1,
      pageSize: 10,
      total: 0,
      infoData: {},
    };
  },
  computed: {
    ...mapState(["menuRoles"]),
  },
  watch: {
    menuRoles() {
      this.routeUpdate();
    },
    tabActiveName() {
      this.page = 1;
      this.$nextTick(() => {
        this.total = 0;
      });
    },
    "$store.state.msg_notread": {
      immediate: true,
      deep: true,
      handler(v) {
        // console.log(v, "vvvvvvvv");
        this.infoData.msg_notread = v;
      },
    },
  },
  created() {
    this.getCount();
  },
  //方法集合
  methods: {
    userInfoListener() {
      this.routeUpdate();
    },
    getCount() {
      this.$http.get("/api/v1_0_0.user/msg_not_read").then((res)=>{
        this.infoData=res.data
          })
      // userInfo2().then((res) => {
      //   // console.log(res, "ressss");
      //   this.infoData = res.data;
      // });
    },

    routeUpdate() {
      var student_user_id = null;
      if (
        this.student_user_id &&
        this.student_user_id + "" !== this.userInfo.id + ""
      ) {
        var taList2 = [];
        for (let i = 0; i < this.taList2.length; i++) {
          if (
            [
              "meStudyCourse",
              "meStudyQuestList",
              "meStudyQuestion",
              "meStudyQuestother",
            ].indexOf(this.taList2[i].value) > -1
          ) {
            taList2.push({
              ...this.taList2[i],
              name: this.taList2[i].name.replace("我的", "TA的"),
            });
          }
        }
        
        this.curTabList = taList2;
        student_user_id = this.student_user_id;
      } else if ((this.menuRoles || []).length > 0) {
        // console.log(this.menuRoles,"this.menuRoles")
        this.curTabList = [...this.taList].filter(
          (d) => this.menuRoles.indexOf(d.value) > -1
        );
      } else if(this.userInfo.type==1){
        this.curTabList = [...this.taList3];
      }else{
        this.curTabList = [...this.taList];
      }
    },
    routeChange({ routerViewDepth }) {
      // console.log(routerViewDepth,"routerViewDepth")
      let $route = this.$route.matched[routerViewDepth + 1] || this.$route;
      // console.log($route,"$route$route")
      let activeName = (
        this.curTabList.filter((d) => $route.name.indexOf(d.value) === 0)[0] ||
        {}
      ).value;
      // console.log(activeName,"activeNameactiveName")

      if (!activeName) {
        this.$handleRoute(this.curTabList[0].value, true);
        this.tabActiveName = this.curTabList[0].value;

        // console.log(this.curTabList[0].value,"this.curTabList[0].value")
      } else {
        this.tabActiveName = activeName;
      }
    },
    currentChange(e) {
      this.page = e || 1;
    },
  },
};
</script>
<style  lang='less' scoped>
li {
  list-style-type: none;
}
.bottom {
  width: 100%;
  box-sizing: border-box;
  margin-top: 5%;
  background-color: #fff;
  border: 1px solid #dcdfe6;
  ::v-deep {
    .el-table__row {
      cursor: pointer;
    }
    .el-tabs__item {
      font-size: 16px;
      height: 50px;
      // width: 130px;
      line-height: 50px;
      text-align: center;
    }
    .el-tabs--border-card {
      box-shadow: 0 0px 0px 0;
      border: 0;
    }
    .el-tabs__content {
      padding: 0;
    }
    .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
      color: #00957e;
    }
    .el-tabs__item:hover {
      color: #00957e;
    }
  }

  .box {
    background-color: #fff;
    position: relative;
    .collection {
      position: relative;
      display: flex;
      margin: 1rem 1rem 0;
      display: flex;
      .collectionBgc {
        background-color: #00957e;
        color: #fff;
      }
      li {
        width: 74px;
        height: 30px;
        border-radius: 2px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2a2a2a;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .content_box {
    padding: 1rem 1rem 0;
  }
  .page {
    display: flex;
    justify-content: center;
    padding: 4% 0 2rem;
  }
}
</style>