<!--  -->
<template>
  <div class="" v-loading="fullscreenLoading">
    <el-row :gutter="50">
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <div class="top_left">
          <!-- 学习 -->
          <div class="study">
            <div class="study_top">
              <p>学课程</p>
              <p>学习进度</p>
              <p>{{ infoData.study_times }}章 /
                {{ infoData.courses_times }}节</p>
            </div>
            <!-- {{infoData.type==1?infoData.student_count:infoData.type==2?infoData.courses_count:''}} -->

            <div class="study_bottom">
              <p>即将达到</p>
              <p>{{ infoData.study_duration }}</p>
            </div>
          </div>
          <!-- 练习 -->
          <div class="study_rigth">
            <el-progress :text-inside="true" color="#00957E" text-color="#ffffff" :stroke-width="14" :percentage="percentage" :show-text="true"></el-progress>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <div class="top_right">
          <div class="practice_left">
            <div class="practice_top">
              <p>做练习</p>
              <p>{{infoData.quest_count}}</p>
            </div>
            <div class="practice_bottom">
              <div>
                <p style="color: #2d93f8">题单</p>
                <p>{{infoData.quest_list_count}}</p>
              </div>
              <div>
                <p style="color: #fd7e00">题目</p>
                <p>{{infoData.d_quest_count}}</p>
              </div>
            </div>
          </div>
          <div class="practice_right">
            <div>
              <p>昨日提交</p>
              <p>{{infoData.z_quest_count}}</p>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { userInfo2 } from "@/api/me.js"

export default {
  props: {
    student_user_id:{
      default: () => 0,
    }
  },
  data () {
    //这里存放数据
    return {
      // 用户详情
      infoData: {},
      percentage: 100,
      fullscreenLoading:false
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  mounted () {

    this.getUserInfo()
  },
  //方法集合
  methods: {
    // 获取用户信息
    getUserInfo () {
      this.fullscreenLoading=true
      userInfo2({ student_user_id: this.student_user_id })
        .then((res) => {
          this.infoData = res.data
          this.percentage =Number(res.data.progress_learned) 
          this.fullscreenLoading=false
          // console.log(res, "获取用户信息成功111")
        })
        .catch((error) => {
           this.fullscreenLoading=false
          // console.log(error, "获取用户信息失败")
        })
    },
    format () {
      let num = 0
      if (this.infoData.courses_times > 0) {
        num = (this.infoData.study_times / this.infoData.courses_times) * 100
      }
      return num + "" + "%"
    },
  },
};
</script>
<style  lang='less' scoped>
.el-col {
  border-radius: 4px;
}
.top_left,
.top_right {
  width: 100%;
  height: 205px;
  background: #ffffff;
  box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 1rem 2rem;
  box-sizing: border-box;
}
.top_left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .study {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .study_top {
      p:nth-child(1) {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #191919;

        line-height: 25px;
      }
      p:nth-child(2) {
        font-size: 21px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 700;
        color: #191919;
        margin-top: 9px;

        line-height: 29px;
      }
      p:nth-child(3) {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #000000;
        letter-spacing: 2px;
        margin-top: 5px;
      }
    }
    .study_bottom {
      p:nth-child(1) {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #838383;

        line-height: 20px;
      }
      p:nth-child(2) {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #838383;
      }
    }
  }
  .study_rigth {
    height: 14px;
    width: 50%;
  }
}
.top_right {
  padding: 1.3rem 2.5rem;
  display: flex;
  justify-content: space-between;
  .practice_left {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .practice_top {
      p:nth-child(1) {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #191919;
        line-height: 25px;
      }
      p:nth-child(2) {
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #191919;
        line-height: 33px;
        margin-top: 10px;
      }
    }
    .practice_bottom {
      width: 10rem;
      display: flex;
      justify-content: space-between;
      display: flex;
      div {
        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #191919;
          line-height: 20px;
          text-align: center;
        }
      }
    }
  }
  .practice_right {
    width: 98px;
    height: 100px;
    text-align: center;
    border-radius: 50%;
    background: #ffffff;
    border: 1px solid #979797;
    display: flex;
    align-items: center;
    justify-content: center;
    p:nth-child(1) {
      color: #707070;
      font-size: 12px;
    }
    p:nth-child(2) {
      color: #000000;
      font-size: 16px;
    }
  }
}
</style>